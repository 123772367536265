import FreemiumBannerResponsive_StudioComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerResponsive/viewer/skinComps/Studio/Studio.skin';


const FreemiumBannerResponsive_Studio = {
  component: FreemiumBannerResponsive_StudioComponent
};


export const components = {
  ['FreemiumBannerResponsive_Studio']: FreemiumBannerResponsive_Studio
};

